import React from "react"
import { graphql } from "gatsby"

import Article from "../components/pages/index/article"
import config from "../globals/variables"
import Transition from '../components/transition/transition'
// import '../pages/index.scss'
// import style from '../pages/index.module.scss'

const CategoryPage = ({ data, location }) => {

  const allCategories = data.wpgraphql.categories.nodes.map(category => category.slug)

  let categorySelected = 0;

  const posts = data.wpgraphql.posts.nodes

  allCategories.forEach((category, i) => {
    if(location.pathname.match(category)) categorySelected = i
  })

  config.selected.category = categorySelected

  return (
    <Transition>
      <div className="projects index">
      {/* <div className={`${style.projects} ${style.index}`}> */}
      {
        posts.map((post, i) => {

          return (
              <Article 
                key={i} 
                color="red" 
                post={post} 
                selected={config.selected} 
              />
          )
        })
      }
      </div>  
  </Transition>  
)}

export default CategoryPage

export const categoryQuery = graphql`
  query categoryQuery($slug: String!)  {
    wpgraphql{
      posts(first: 200, where: {categoryName: $slug}) {
        nodes {
          title
          slug
          categories{
            nodes{
              slug
            }
          }
          project {
            preview {
              __typename
              ... on WPGraphQL_Post_Project_Preview_Image {
                ...Image
              }
              ... on WPGraphQL_Post_Project_Preview_Video {
                video {
                  mediaItemUrl
                  mediaDetails {
                    width
                    height
                  }
                }
                image {
                  mediaDetails{
                    width
                    height
                    sizes{
                      sourceUrl
                      width
                      height
                    }
                  }
                }
              }
            }
            hero {
              __typename
              ... on WPGraphQL_Post_Project_Hero_Image {
                image{
                  altText
                  mediaDetails {
                    sizes{
                      sourceUrl
                      width
                      height
                    }
                  }
                }
              }
              ... on WPGraphQL_Post_Project_Hero_Video {
                video {
                  mediaItemUrl
                }
                image{
                  altText
                  mediaDetails {
                    sizes{
                      sourceUrl
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
      categories {
        nodes {
          slug
        }
      }
    }
  }
`
